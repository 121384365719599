import React from "react"
import logo from '../assets/media/logo.png';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    return (
        <footer className="footer py-4">
            <div className={'container'}>
                <div className="row">
                    <div className="col-12 col-md">
                        <div className="site-logo">
                            <img src={logo} alt="Logo"/>
                        </div>

                        <hr className={'d-block d-md-none my-4'}/>

                        <div className="rights-reserved d-none d-md-block pt-4 fw-light">
                            © {moment().format('YYYY')} | All Rights Reserved | Kartik Patel
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex h-100 justify-content-end align-items-sm-end flex-column">
                            <div className="social-icons">
                                <a href="https://www.linkedin.com/in/kartik-patel-91b073122/" target="_blank"
                                   rel="noreferrer">
                                    <FontAwesomeIcon icon={faLinkedinIn}/>
                                </a>
                            </div>

                            <div className="email">
                                <a className={'fw-light'}
                                   href="mailto:kartikpatel@outlook.co.nz?subject=website enquiry">
                                    kartikpatel@outlook.co.nz
                                </a>
                            </div>

                            <div className="rights-reserved d-block d-md-none fw-light">
                                © {moment().format('YYYY')} | All Rights Reserved | Kartik Patel
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;