const urlPaths = {
    home: '/',
    about: '/about/',
    project: '/projects/',
    contact: '/contact/',
}

const routes = [
    {
        name: 'Home',
        url: urlPaths.home,
    },
    {
        name: 'About',
        url: urlPaths.about,
    },
    {
        name: 'Projects',
        url: urlPaths.project,
    },
    {
        name: 'Contact',
        url: urlPaths.contact,
    }
];

export { urlPaths }
export default routes