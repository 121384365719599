import * as React from "react"
import routes from "../routes";
import logo from '../assets/media/logo.png';
import { Link } from "gatsby"

const Navigation = () => {
    return (
        <nav className={`navbar navbar-expand-md navbar-dark bg-dark`}>
            <div className="container">
                <a className="navbar-brand site-logo" href="/">
                    <img src={logo} alt={'logo'} width={'180'} height={'180'}/>
                </a>

                <div className="navbar-toggler-wrapper d-flex d-lg-none align-items-end flex-column position-relative">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#mainNav" aria-controls="mainNav" aria-expanded="false"
                            aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-bar bar-top" />
                        <span className="navbar-toggler-bar bar-middle" />
                        <span className="navbar-toggler-bar bar-bottom" />
                    </button>
                </div>

                <div className="collapse navbar-collapse" id="mainNav">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {routes?.map((item, idx) => (
                            <li className={`nav-item ${idx === 0 ? 'first-item' : ''}`} key={idx}>
                                <Link to={item?.url} className={'nav-link'}>
                                    <span>{item?.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navigation