import React from "react"
import Navigation from "../navigation";
import Footer from "../footer";

const Layout = (props) => {
    const {
        children,
    } = props;

    if (children.props.location.pathname === '/') {
        return <>{children}</>
    }

    return (
        <>
            <Navigation />
            <div className={'pt-5'}>
                {children}
            </div>
            <Footer />
        </>
    )
}

export default Layout