import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";
import "lightbox2/dist/css/lightbox.css"
import "lightbox2/dist/js/lightbox-plus-jquery.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "./src/assets/scss/main.scss";
import React from "react"
import Layout from "./src/components/layouts/layout";

export const wrapPageElement = ({ element, props }) => {
    return (
        <Layout {...props}>
            {element}
        </Layout>
    )
}